import Vue from 'vue'
import App from './App.vue'
import store from './store'
import '../static/css/common.scss' /*引入公共样式*/

import router from './router'
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  console.log(targetPath)
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});

import "@/rem.js";

// 引入自己封装的cookie方法
import cookie from "./utils/cookie";
Vue.prototype.cookie = cookie;




Vue.config.productionTip = false

// vant 按需引用
import vant from './vantUiComponents/vant'
import 'vant/lib/index.css';
Vue.use(vant)


// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()
// Vue.use(vConsole);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
